import Vue from 'vue';
import VuePromiseBtn from 'vue-promise-btn';
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag";
import {ModalPlugin} from 'bootstrap-vue'


require('../bootstrap');
window.axios.defaults.baseURL = '/spa'


// For more options see below
Vue.use(VueReCaptcha, {siteKey: window.G_RECAPTCHA_KEY});
Vue.use(VuePromiseBtn);
Vue.use(VueGtag, {
    config: {id: window.GTAG_ID}
});

Vue.use(ModalPlugin);

Vue.component('login', require('./components/Login.vue').default);
Vue.component('preventa', ()=>import('./components/Preventa.vue'));

Vue.component('Dashboard', () => import('./pages/dashboard/Index.vue'));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
