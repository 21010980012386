<template>
    <div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div class="card bg-secondary border-0">
                        <div v-if="!isLoading" class="card-header bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-3">
                                <small class="text-white">Inicia sesión con</small>
                            </div>
                            <div class="btn-wrapper text-center">
                                <button @click="loginWithApple" class="btn btn-block bg-white btn-icon">
              <span class="btn-inner--icon"
              ><img src="/img/apple.svg" style="width: 20px"
              /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </button>
                                <button @click="loginWithGoogle" class="btn btn-block bg-white btn-icon">
              <span class="btn-inner--icon">
                  <img src="/img/google.svg" style="width: 20px"/></span>
                                    <span class="btn-inner--text">Google</span>
                                </button>
                            </div>
                        </div>
                        <div v-else class="card-header bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-3 py-5">
                                <small class="text-white">Cargando...</small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from "firebase";

// Initialize Firebase
firebase.initializeApp(window.firebaseConfig);
firebase.analytics();

const auth = firebase.auth();

export default {
    name: 'Login',
    data: () => ({
        isLoading: false,
    }),
    methods: {
        async loginWithApple(){
            const provider = new firebase.auth.OAuthProvider("apple.com");
            provider.addScope("email");
            provider.addScope("name");
            const result = await auth.signInWithPopup(provider);
            await this.handleAuth(result);
        },
        async loginWithGoogle(){
            const provider = new firebase.auth.GoogleAuthProvider();
            const result = await auth.signInWithPopup(provider);
            await this.handleAuth(result);
        },
        async handleAuth(result){
            this.isLoading = true;
            const user = result.user;

            const fd = new FormData();
            fd.append("idToken", user.Aa);
            fd.append('refreshToken', user.refreshToken);
            try {
                const response = await axios.post("/login", fd);

                if (response.data.ok) {
                    window.location.href = '/redirect'
                }

            } catch (e) {
                return [
                    true,
                    {message: "Hubo un error"}
                ];
            }
        }

    }
};
</script>
